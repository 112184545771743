import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const ChatHistory = ({ chats, setCurrentmessage, selectedChatId, setSelectedChatId }) => {
  return (
    <List className="chat-history">
      {chats.map((chat, index) => (
        <div key={chat.id}>
          <ListItem
            button
            selected={selectedChatId === chat.id}
            onClick={() => setSelectedChatId(chat.id)}
            className="list-item"
            style={{
              background: selectedChatId === chat.id ? 'white' : 'inherit',
              padding: '10px',
              borderRadius: '5px',
              maxWidth: '100%'
            }}
          >
            <ListItemText 
              primary={chat.session_latest_message ? chat.session_latest_message : `Session ${chats.length - index}`}
              className="truncate-text"
              style={{color: selectedChatId === chat.id ? 'black' : 'white'}}
            />
          </ListItem>
          {index !== chats.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  );
};

export default ChatHistory;