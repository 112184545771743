import MainHeader from "./components/MainHeader";
import { Outlet } from "react-router-dom";


function Header(){
    return (
    <>
        <MainHeader />
        <Outlet/>
    </>
    );
}

export default Header;