// useEffect is used to fetch the chats from the backend using the fetchChats function

import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import "../../App.css";
import ChatHistory from "./ChatHistory";
import ChatUI from "./ChatUI";
import axiosInstance from "../../axios";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';


function Chatbot() {
  const [currentmessage, setCurrentmessage] = useState("");
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(true);

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    if (selectedChatId) {
      fetchMessages(selectedChatId);
    } else {
      setMessages([]);
    }
  }, [selectedChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const toggleChatHistory = () => {
    setIsChatHistoryVisible(!isChatHistoryVisible);
  };

  // Fetch all chats from backend
  const fetchChats = async () => {
    try {
      // get all chats from backend
      const response = await axiosInstance.get(`chatbot/chats/`);
      console.log("Response: ", response)
      console.log("Response.data: ", response.data)
      setChats(response.data);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };
  // ("Chats", chats)
  // Fetch specific chat from backend
  const fetchMessages = async (chatId) => {
    try {
      const response = await axiosInstance.get(`chatbot/chats/${chatId}/`);
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  
  // Send message to backend
  const sendMessage = async () => {
    setMessages([
      // Say we have arr1 = [1, 2, 3] then arr2 = [...arr1, 4] will give arr2 = [1, 2, 3, 4
      // spread operator  
      ...messages,
      {
        content: inputMessage,
        role: "user",
      },
    ]);
    setCurrentmessage(inputMessage);
    setInputMessage("");
    setIsAssistantTyping(true);

    // if new chat than backend me chatid is created and sent to frontend
    // if old chat than backend me chatid is already there and sent sent from frontend
    try {
      // Simulate a delay for the typewriting effect
      const delay = 1000 + Math.random() * 1000; // Random delay between 1-2 seconds
      setTimeout(async () => {
        try {
          const response = await axiosInstance.post(`chatbot/chats/`, {
            // if selectedChatId is undefined than it means that it is a new chat
            // if selectedChatId is not undefined than it means that it is an old chat and retrieved from the backend
            chat_id: selectedChatId || undefined,
            message: inputMessage
          });

          // If there was no selected chat, set the selected chat to the newly created one
          if (!selectedChatId) {
            setSelectedChatId(response.data.chat_id);
            setChats([{ id: response.data.chat_id }, ...chats]);
          } else {
            fetchMessages(selectedChatId);
          }
        } catch (error) {
          console.error("Error sending message:", error);
          setMessages([
            ...messages,
            {
              content:
                "error",
              role: "assistant",
            },
          ]);
        } finally {
          setIsAssistantTyping(false);
        }
      }, delay);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // New Chat button get a empty chat from backend
  const createNewChat = async () => {
    try {
      const response = await axiosInstance.post(`chatbot/chats/`, {
      });
      const newChat = response.data;

      setChats([newChat, ...chats]);
      setSelectedChatId(newChat.id);
    } catch (error) {
      console.error("Error creating a new chat:", error);
    }
  };

  function formatMessageContent(content) {
    const sections = content.split(/(```[\s\S]*?```|`[\s\S]*?`)/g);
    return sections
      .map((section) => {
        if (section.startsWith("```") && section.endsWith("```")) {
          section = section.split("\n").slice(1).join("\n");
          const code = section.substring(0, section.length - 3);
          return `<pre><code class="code-block">${code}</code></pre>`;
        } else if (section.startsWith("`") && section.endsWith("`")) {
          const code = section.substring(1, section.length - 1);
          return `<code class="inline-code">${code}</code>`;
        } else {
          return section.replace(/\n/g, "<br>");
        }
      })
      .join("");
  }
  return (
    <div className="App">
      <div className="chat-container">
        <div className={`chat-history-container ${isChatHistoryVisible ? '' : 'collapsed'}`}>
          <Button 
            variant="contained" 
            color="secondary" 
            className="new-chat-button" 
            onClick={createNewChat}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                createNewChat();
              }
            }}
            style={{ backgroundColor: '#404258', display: isChatHistoryVisible ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start', width: '96.7%', margin: '5px'}}
            >
            <Avatar alt="Profile Picture" src="https://rainierpeakai-datastore.s3.amazonaws.com/rainierpeakai_logo.png" style={{ display: isChatHistoryVisible ? 'block' : 'none' }}/>
            <strong style={{marginLeft: '10px'}} >New Chat</strong>
          </Button>
          <ChatHistory
            chats={chats}
            setCurrentmessage={inputMessage}
            selectedChatId={selectedChatId}
            setSelectedChatId={setSelectedChatId}
          />
        </div>
        <div className={`chat-input-ui-container ${isChatHistoryVisible ? '' : 'expanded'}`}>
          <ChatUI
            messages={messages}
            currentmessage={currentmessage}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            sendMessage={sendMessage}
            isChatHistoryVisible={isChatHistoryVisible}
            formatMessageContent={formatMessageContent}
            isAssistantTyping={isAssistantTyping}
            messagesEndRef={messagesEndRef}
            toggleChatHistory={toggleChatHistory}
          />
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
