import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Contact from './Contact';

const StyledButton = styled(Button)(({ theme }) => ({
  // margin: theme.spacing(4),
  marginTop: '-15%',
  marginLeft: '2.3%',
  // marginBottom: '10%',
  padding: '17px',
  color: 'white',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '45%',
  textAlign: 'left',
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),
  fontWeight: 700,
  fontSize: '3.4rem',
  color: 'white',
  fontFamily: 'Comfortaa, sans-serif',
}));

const StyledCardMedia = styled(CardMedia)({
  height: '30%',
  width: '30%',
  paddingTop: '30%', 
  backgroundPosition: 'center',
});

function Home() {
  const navigate = useNavigate();
  const cards = [
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/ayurveda.png', text: 'Personalized Medicine Recommendations' },
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/symptom.png', text: 'Symptom Analysis' },
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/diet.png', text: 'Diet Suggestions' },
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/health.png', text: 'Lifestyle Advice' },
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/reminder.png', text: 'Follow-up Reminders' },
    { image: 'https://rainierpeakai-datastore.s3.amazonaws.com/monitor.png', text: 'Health Tracking' },
  ]
  return (
    <>
      <div className="root" style={{ 
          backgroundImage: `url(https://rainierpeakai-datastore.s3.amazonaws.com/hero_image.png)` , 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          height: '100vh' 
      }}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
            <StyledTypography variant="h5">
              Discover the power of nature with our personalized Ayurvedic medicine recommendations.
            </StyledTypography>
        </div>
        <StyledButton
            variant="outlined"
            onClick={() => navigate('/register')}
        >
          Register with Us
        </StyledButton>
      </div>
      <div style={{
        backgroundImage: 'radial-gradient(circle, #545353, #000000)',
        top: 0,
        left: 0
      }}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <Typography variant="h2" align="center" gutterBottom sx={{marginTop: '40px', color: 'white', fontFamily: 'Comfortaa, sans-serif', fontWeight: 700}}>
              Our Services
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom sx={{ paddingBottom: '20px', color: 'white', fontFamily: 'Comfortaa, sans-serif'}}>
              Explore our range of personalized Ayurvedic services designed to improve your health and wellbeing.
          </Typography>
          <Grid container spacing={1} sx={{width: '80%'}}>
              {cards.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                          <StyledCardMedia
                              image={card.image}
                              title={`Image for box ${index + 1}`}
                          />
                      </div>
                      <CardContent>
                          <Typography variant="subtitle1" align="center" style={{fontFamily: 'PT Sans Caption, sans-serif'}}>
                          {card.text}
                          </Typography>
                      </CardContent>
                  </Card>
              </Grid>
              ))}
          </Grid>
        </div>
        <Contact />
      </div>
    </>
  );
}

export default Home;