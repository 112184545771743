import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '95vh',
  backgroundImage: 'radial-gradient(circle, #545353, #000000)'
}));

const FormDiv = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(-15),
  '& > *': {
    margin: theme.spacing(1),
  },
  color: 'white',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex', // makes the button a flex container
  justifyContent: 'center',
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(-5),
  color: 'white',
  marginBottom: theme.spacing(18),
  fontFamily: 'Comfortaa, sans-serif',
  fontWeight: 700,
}));

function Contact() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <RootDiv>
        <TitleTypography variant="h2" align="center" gutterBottom sx={{color: 'white'}}>
            Contact Us
        </TitleTypography>
        <FormDiv noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="name" 
                        label="Name" 
                        variant="outlined" 
                        fullWidth 
                        onChange={handleChange} 
                        value={form.name} 
                        sx={{
                          color: 'white',
                          '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'white',
                          },
                      }}  
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id="email" 
                        label="Email" 
                        variant="outlined" 
                        fullWidth 
                        onChange={handleChange} 
                        value={form.email} 
                        sx={{
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        id="subject" 
                        label="Subject" 
                        variant="outlined" 
                        fullWidth 
                        onChange={handleChange} 
                        value={form.subject} 
                        sx={{
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="message"
                        label="Message"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange} 
                        value={form.message}
                        sx={{
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white',
                            },
                        }} 
                    />
                </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonStyled 
                    variant="outlined" 
                    color="primary" 
                    type="submit" 
                    style={{ color: 'white', borderColor: 'white' }}
                >
                    Submit
                </ButtonStyled>
            </div>
        </FormDiv>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{color: 'black'}}>{"Submission Successful"}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: 'black'}}>
                    Your message is successfully submitted. Our team will contact you soon.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" sx={{color: 'black'}}>
                    OK
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus sx={{color: 'black'}}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </RootDiv>
  );
}

export default Contact;