import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Header from "./Header";
import Chatbot from "./components/chatbot/Chatbot";
import Home from "./components/others/Home";
import About from "./components/others/About";
import Contact from "./components/others/Contact";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Logout from "./components/auth/logout";
import Activate from "./components/auth/Activate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="chatbot" element={<Chatbot />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;