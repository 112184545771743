import * as React from 'react';
import Message from "./Message";
import ChatInput from "./ChatInput";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import MenuIcon from '@mui/icons-material/Menu';

const ChatUI = ({
  messages,
  inputMessage,
  currentmessage,
  setInputMessage,
  sendMessage,
  isChatHistoryVisible,
  formatMessageContent,
  isAssistantTyping,
  messagesEndRef,
  toggleChatHistory
}) => {
  const [copiedMessage, setCopiedMessage] = React.useState(null);
  
  const sortedMessages = [...messages].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  
  const handleCopyMessage = (message) => {
    navigator.clipboard.writeText(message);
    setCopiedMessage(message);
  };
  
  return (
    <div className="chat-ui">
      <IconButton
          style={{ color: 'white', position: 'fixed', top: '50%', transform: 'translateY(-50%) ', left: isChatHistoryVisible ? '20%' : '0%', transition: 'left 0.3s ease' }}   
          onClick={toggleChatHistory}>
        <MenuIcon />
      </IconButton>
      {sortedMessages.length === 0 && (
        <div className="centered-div" style={{left: isChatHistoryVisible ? '60%' : '50%', transition: 'left 0.3s ease'}}>
          <div className="center-image-div"></div>
          <h2 className="center-heading">Ayurveda Insights</h2>
          <p className="center-image-para">Ayurveda specialist sharing traditional wellness advice and insights! Lets find the best products to help you get better and improve. Get product recommendations with product links right here!!</p>
          <span>By <a className="center-link" href="https://www.rainierpeak.co/">RainierPeakAI</a></span>
        </div>
      )}
      <div className="chat-messages">
        {sortedMessages.map((message, index) => (
          <React.Fragment key={index}>
          <div className="message-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt="Profile Picture" src="https://rainierpeakai-datastore.s3.amazonaws.com/user.png" />
              <h4 style={{ margin: '0 0 0 10px', fontSize: 'small', color: 'white' }}>You</h4>
            </div>
            <div className={`message ${true ? 'user' : 'assistant'}`}>
              <Message
                message={message.user_request !== undefined ? message.user_request : currentmessage}
                user={true}
              />
              <IconButton size="small" onClick={() => handleCopyMessage(message.user_request !== undefined ? message.user_request : currentmessage)}>
                {copiedMessage === (message.user_request !== undefined ? message.user_request : currentmessage) ? <CheckIcon /> : <FileCopyIcon />}
              </IconButton>
            </div>
          </div>
          <div className="message-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt="Profile Picture" src="https://rainierpeakai-datastore.s3.amazonaws.com/rainierpeakai_logo.png" />
              <h4 style={{ margin: '0 0 0 10px', fontSize: 'small', fontWeight: 'bold', color: 'white' }}>InsightGPT</h4>
            </div>
            <div className={`message ${false ? 'user' : 'assistant'}`}>
              <Message
                message={message.assistant_reply}
                user={false}
              />
              {isAssistantTyping && (
                <div className="message assistant">
                  <div className="typing-indicator">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                </div>
              )}
              {!isAssistantTyping && (
                <IconButton size="small" onClick={() => handleCopyMessage(message.assistant_reply)}>
                  {copiedMessage === message.assistant_reply ? <CheckIcon /> : <FileCopyIcon />}
                </IconButton>
              )}
            </div>
          </div>
        </React.Fragment>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <ChatInput
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        sendMessage={sendMessage}
      />
    </div>
  );
};

export default ChatUI;