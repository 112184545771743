import React from "react";

const Message = ({ message, user }) => {
  return (
    <div
      className={`message ${user ? "user" : "assistant"}`}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};

export default Message;