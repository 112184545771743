import React from "react";
import { useNavigate } from "react-router-dom";

function About(){

    const navigate = useNavigate();
    
    const goToContact = () => {
        navigate('/contact');
    };
    return (
    <>
        {/* <Navbar /> */}
        <div>
            About Page
            <button onClick={() => goToContact()} >Goto Contact</button>
        </div>
    </>
    );
}

export default About;