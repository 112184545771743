import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

export default function Activate() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundImage: 'radial-gradient(circle, #545353, #000000)'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40%',
                    height: '25%',
                    backgroundColor: '#f5f5f5',
                    padding: '20px',
                    borderRadius: '10px',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Email Activation link has been sent to the registered email
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please click on the link in order to Activate your account
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                >
                    <Button variant="contained" color="primary" style={{ marginRight: '10px' }} component={NavLink} to="/login">
                        Login
                    </Button>
                    <Button variant="contained" color="secondary" component={NavLink} to="/">
                        OK
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}