import * as React from 'react';
import { useState } from 'react';
import axiosInstance from '../../axios';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
//MaterialUI
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

const StyledContainer = styled(Container)({
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
});

const StyledParent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

const AvatarStyled = styled(Avatar)({
    margin: 1,
    backgroundColor: 'secondary.main',
});

const StyledForm = styled('form')({
    width: '100%', // Fix IE 11 issue.
    marginTop: '24px',
});

const StyledButton = styled(Button)({
    margin: '24px 0 16px',
    color: 'white',
    borderColor: 'white'
});

export default function SignUp() {
    const navigate = useNavigate();
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);

    const validatePassword = (password) => {
        if (password.length < 8) {
            setPasswordError('Password should be greater than 8 characters');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const initialFormData = Object.freeze({
        email: '',
        username: '',
        password: '',
    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [formData, updateFormData] = useState(initialFormData);
    const [showPassword, setShowPassword] = useState(false);


    const handleChange = (e) => {
        updateFormData({
            ...formData,
            // Trimming any whitespace
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        
        if (!validatePassword(formData.password)) {
            return; // Do not proceed if validation fails
        }
        setLoading(true);

        axiosInstance
            .post(`user/create/`, {
                email: formData.email,
                username: formData.username,
                password: formData.password,
            })
            .then((res) => {
                navigate('/activate');
                console.log(res);
                console.log(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '95vh',
            backgroundImage: 'radial-gradient(circle, #545353, #000000)'
        }}
        >
            <StyledContainer component="main" maxWidth="xs">
                <CssBaseline />
                <div>
                    <StyledParent>
                        <AvatarStyled></AvatarStyled>
                    </StyledParent>
                    <StyledParent>
                        <Typography component="h1" variant="h5">
                                Sign up
                        </Typography>
                    </StyledParent>
                    <StyledForm noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleChange}
                                    sx={{
                                        color: 'white',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    onChange={handleChange}
                                    sx={{
                                        color: 'white',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={handleChange}
                                    error={Boolean(passwordError)}
                                    helperText={passwordError}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        color: 'white',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive updates, marketing promotions via email."
                                />
                            </Grid>
                        </Grid>
                        <StyledButton
                            type="submit"
                            fullWidth
                            variant="outlined"
                            onClick={handleSubmit}
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} />}
                        >
                            Sign Up
                        </StyledButton>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link variant="body2" component={RouterLink} to="/login" sx={{textDecoration: 'none'}}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </StyledForm>
                </div>
            </StyledContainer>
        </div>
    );
}