import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundImage: 'linear-gradient(to right, #3c3834, #142028)',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    margin: theme.spacing(1, 1.5),
    // backgroundColor: '#fff',
    color: '#fff',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1, 1.5),
    backgroundColor: '#fff',
}));

function MainHeader() {
    const isLoggedIn = !!localStorage.getItem('access_token');

    return (
        <React.Fragment>
            <CssBaseline />
            <StyledAppBar
                position="static"
                color="default"
                elevation={0}
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                            flexGrow: 1,
                            fontWeight: 'bold',
                            fontSize: '2rem',
                            color: '#ffffff',
                            fontFamily: 'Patua One, serif',
                        }}
                    >
                        <StyledLink
                            component={NavLink}
                            to="/"
                            underline="none"
                            color="textPrimary"
                        >
                            Ayurveda Insights
                        </StyledLink>
                        <span style={{fontSize: '0.8rem', color: 'grey', fontFamily: 'sans-serif' }}>by RainierPeakAI</span>
                    </Typography>
                    {isLoggedIn ? (
                        <>
                            <StyledButton color="primary" variant="outlined" component={NavLink} to="/chatbot">
                                Chatbot
                            </StyledButton>
                            <StyledButton color="primary" variant="outlined" component={NavLink} to="/account">
                                Account
                            </StyledButton>
                            <StyledButton color="secondary" variant="outlined" component={NavLink} to="/logout">
                                Logout
                            </StyledButton>
                        </>
                    ) : (
                        <>
                            <StyledButton color="primary" variant="outlined" component={NavLink} to="/">
                                Home
                            </StyledButton>
                            <StyledButton color="primary" variant="outlined" component={NavLink} to="/contact">
                                Contact
                            </StyledButton>
                            <StyledButton color="primary" variant="outlined" component={NavLink} to="/register">
                                Register
                            </StyledButton>
                        </>
                    )}
                </Toolbar>
            </StyledAppBar>
        </React.Fragment>
    );
}

export default MainHeader;
